import { createReducer, on } from '@ngrx/store';
import * as PageActions from './page.actions';
import { initialState } from './page.state';


export const pageReducer = createReducer(
  initialState,
  on(PageActions.setTitle, (state, { title }) => ({ ...state, title })),
  on(PageActions.setReadonly, (state, { readonly }) => ({ ...state, readonly }))
);
