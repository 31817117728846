<div class="row text-center">
  <div class="col-md-4">
      <div class="btn-group">
          <div
                  class="btn btn-primary"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
          >
              Precedente
          </div>
          <div
                  class="btn btn-outline-secondary"
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
          >
              Oggi
          </div>
          <div
                  class="btn btn-primary"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="fetchEvents()"
          >
              Successivo
          </div>
      </div>
  </div>
  <div class="col-md-4">
      <h3>
          {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
      </h3>
  </div>
  <div class="col-md-4">
      <div class="btn-group" >
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month"

          >
              Mese
          </div>
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Week)"
                  [class.active]="view === CalendarView.Week"
          >
              Settimana
          </div>
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day"
          >
              Giorno
          </div>
      </div>
  </div>
</div>

<br/>

<div *ngIf="events$ | async; let events">
<div [ngSwitch]="view">
  <!--<mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="openEvent($event.day.date)"
          (eventClicked)="eventClicked($event.event)"
  >
  </mwl-calendar-month-view>-->
  <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"

          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent($event.event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [dayStartHour]="dayStartHour"
          [dayEndHour]="dayEndHour"
          (eventClicked)="handleEvent($event.event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          [dayStartHour]="dayStartHour"
          [dayEndHour]="dayEndHour"
          (eventClicked)="handleEvent($event.event)"
  >
  </mwl-calendar-day-view>
</div>
</div>
<div class="button_sticky">
  <button mat-fab color="primary" title="Aggiungi" aria-label="Aggiungi evento" (click)="addEvento()">
      <mat-icon>add</mat-icon>
  </button>
</div>
