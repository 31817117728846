import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

let count_spinner = 0;
let timeoutId: any; // Timer per il timeout dello spinner

export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const spinner = inject(NgxSpinnerService);
  // Controlla se l'intestazione per disabilitare lo spinner è presente
  const disableSpinner = req.headers.get('X-Disable-Spinner') === 'true';
  // console.log('1 count_spinner', count_spinner);
  if (!disableSpinner) {
    spinner.show(undefined, {
      fullScreen: true,
      type: 'square-jelly-box', // 'pacman'
      bdColor: 'rgba(0,0,0,0.4)',
      size: 'default'
    });
    count_spinner++;
    // Avvia il timer solo se è il primo spinner attivo
    if (count_spinner === 1) {
      timeoutId = setTimeout(() => {
        // Forza la chiusura dello spinner e reset del contatore dopo 1 minuto
        count_spinner = 0;
        spinner.hide();
        console.warn('Lo spinner è stato chiuso automaticamente per timeout.');
      }, 60000); // Timeout: 60 secondi
    }
    // console.log('2 count_spinner', count_spinner);
  }
  return next(req).pipe(
    finalize(
      () => {
        if (!disableSpinner) {
          count_spinner--;
        }
        // console.log('3 count_spinner', count_spinner);
        if (count_spinner === 0) {
          clearTimeout(timeoutId); // Timer cancellato
          spinner.hide();
        }
      }
    )
  );
};
