import { Component } from '@angular/core';
import { LoginComponent } from './login/login.component';
import {MatCardModule} from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { EtichettaIndex } from './interfaces/etichetta-index';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-main-index',
  standalone: true,
  imports: [LoginComponent, MatCardModule, CommonModule, RouterModule],
  templateUrl: './main-index.component.html',
  styleUrl: './main-index.component.scss'
})
export class MainIndexComponent {
  listaEtichette : EtichettaIndex[] = [
    {
      id: 1,
      titolo: 'Anagrafica',
      sotto_titolo: 'Anagrafica unificata per clienti, fornitori, prospect ed utenti.'
    },
    {
      id: 2,
      titolo: 'Calendario',
      sotto_titolo: 'Gestisci i tuoi appuntamenti, i lavori da svolgere e le risorse a disposizione della tua azienda.'
    }
  ]
}
