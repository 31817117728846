import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class ConnectHomeHeaderService {
  private sidenav!: MatSidenav;

  constructor() { }

  getSidenav(): MatSidenav {
    return this.sidenav;
  }

  setSidenav(value: MatSidenav): void {
    // console.log('valore matsidenav 2: ' + Object.values(value));
    // console.log('valore matsidenav 2: ' + value);
    this.sidenav = value;
  }
}
