import { Component } from '@angular/core';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { HeaderIndexComponent } from './header-index/header-index.component';
import { CommonModule } from '@angular/common';
import { IconsComponent } from '../components/icons/icons.component';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { AuthenticationService } from '../services/authentication.service';
import { ConnectHomeHeaderService } from '../services/connect-home-header.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [HeaderHomeComponent, HeaderIndexComponent, CommonModule, IconsComponent, MatSidenavModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  private sidenav_home!: MatSidenav;
  constructor(private authenticationService: AuthenticationService,
    private service_homeheader: ConnectHomeHeaderService) {

  }
  viewMainHome(): boolean {
    return this.authenticationService.isLoggedIn;
  }

  // quando clicco sulla W si muove il menù
  toggleSidenav(): void {
    // console.log('HeaderComponent: toggleSidenav');
    this.sidenav_home.toggle();
  }

  ngDoCheck(): void {
    // servizio per prendere l'id #sidenav per il menu
    this.sidenav_home = this.service_homeheader.getSidenav();
    // console.log('HeaderComponent: ngDoCheck');
}
}
