
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CompanyState } from './company.reducer';

export const selectCompanyState = createFeatureSelector<CompanyState>('company');

export const selectCompanyDefaultValues = createSelector(
  selectCompanyState,
  (state: CompanyState) => state.company
);
