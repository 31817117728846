import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MenuService } from './services/menu.service';
import { Router } from '@angular/router';
import { UserState } from '../../../storage-ngrx/user/user.reducer';
import { Store } from '@ngrx/store';

interface Menu {
  voce: string;
  id: number;
  livello: string;
  link: [] | null;
  sub: Menu[] | null;
}
interface JsonMenu {
  menu?: Menu[];
}
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  private json_menu: JsonMenu = {};
  @ViewChild('div_menu', { static: false }) div_menu!: ElementRef;


  constructor(private renderer: Renderer2, private router: Router,
    private menuService: MenuService, private store: Store<{ user: UserState }>) {

  }

  /*
      json_menu = {
          menu: [
              {
                  voce: 'Impostazioni', id: 1, livello: '0', link: null, sub:
                      [
                          {
                              voce: 'Azienda', id: 2, livello: '1', link: null, sub:
                                  [
                                      {
                                          voce: 'Crea elemento',
                                          id: 3,
                                          livello: '2',
                                          link: [0, 0, '/page/impostazioni/crea-elemento'],
                                          sub: null
                                      },
                                      {
                                          voce: 'Azienda elemento',
                                          id: 4,
                                          livello: '2',
                                          link: [1, 0, '/page/impostazioni/azienda-elemento'],
                                          sub: null
                                      },
                                      {voce: 'Permessi', id: 6, livello: '2', link: [1, 0, '/page/impostazioni/permessi'], sub: null}
                                  ]
                          },
                          {
                              voce: 'Anagrafica', id: 5, livello: '1', link: [1, 0, '/page/impostazioni/permessi'], sub: null
                          }
                      ]
              },
              {
                  voce: 'Home', id: 7, livello: '0', link: [1, 0, '/'], sub: null
              },
              {
                  voce: 'Calendario', id: 8, livello: '0', link: [1, 0, '/page/calendario'], sub: null
              }
          ]
      };
  */
  /**
   * Restituisce un array di tre interi:
   * [0, 0, 0] 0: ogni intero è l'id della voce di menu. Tre, uno per ogni livello
   * @param url string della pagina corrente
   */
  findPositionFromUrl(url: string): number[] {
    if (url === '/') {
      return [-1, -1, -1];
    }
    let arrayId = [0, 0, 0, 0];
    if (this.json_menu && this.json_menu.menu) {
      for (const i of this.json_menu.menu) {
        if (arrayId[3] === 0) {
          arrayId = this.findIdFromUrl(i, url, arrayId);
        } else {
          break;
        }
      }
    }

    // elimino l'ultimo elemento
    arrayId.pop();
    return arrayId;
  }

  findIdFromUrl(itemData: any, url: string, arrayId: any[]): number[] {
    if (itemData.sub) {
      if (itemData.livello === 0) {
        arrayId[0] = itemData.id;
      }
      for (const element of itemData.sub) {
        if (arrayId[3] === 0) {
          if (element.livello === 1) {
            arrayId[1] = element.id;
          }
          arrayId = this.findIdFromUrl(element, url, arrayId);
        } else {
          break;
        }
      }
    } else {
      if (itemData.livello === 0) {
        if (itemData.link) {
          arrayId = [itemData.id, 0, 0, 0];
          if (itemData.link[2] === url) {
            arrayId = [itemData.id, 0, 0, 1];
          }
        } else {
          arrayId[0] = itemData.id;
        }
      } else if (itemData.livello === 1) {
        if (itemData.link) {
          arrayId[1] = itemData.id;
          arrayId[2] = 0;
          if (itemData.link[2] === url) {
            arrayId[3] = 1;
          }
        } else {
          arrayId[1] = itemData.id;
        }
      } else if (itemData.livello === 2) {
        arrayId[2] = itemData.id;
        if (itemData.link[2] === url) {
          arrayId[3] = 1;
        }
      }
    }
    return arrayId;
  }

  /**
   * La funzione renderizza il menu
   */
  buildMenu(array_posizioni: any): HTMLParagraphElement {
    const ul: HTMLParagraphElement = this.renderer.createElement('ul');
    ul.setAttribute('class', 'nav flex-column');
    ul.setAttribute('id', 'ul_menu_padre');
    if (this.json_menu && this.json_menu.menu) {
      this.json_menu.menu.forEach((element: any) => {
        this.renderer.appendChild(ul, this.getMenuItem(element, ul, array_posizioni));
      });
    }

    return ul;
  }

  /**
   *
   * @param itemData riga JSON
   */
  getMenuItem(itemData: any, ul_padre: any, array_posizioni: any[]): HTMLParagraphElement {
    const item: HTMLParagraphElement = this.renderer.createElement('li');
    item.setAttribute('class', 'nav-item');
    const a: HTMLParagraphElement = this.renderer.createElement('a');
    // a.innerHTML = itemData.descrizione;
    a.innerHTML = itemData.voce;
    a.setAttribute('id', 'idmenu_' + itemData.id);
    if (itemData.sub) {
      a.setAttribute('href', '#menuclick_' + itemData.id);
      a.setAttribute('data-bs-toggle', 'collapse');
      // a.setAttribute('aria-controls', 'menuclick_' + itemData.id);
      let css_class_colore = '';
      if (itemData.livello === 0) {
        if (array_posizioni[0] === itemData.id) {
          a.setAttribute('aria-expanded', 'true');
          css_class_colore = ' evidenzia_espansione ';
        } else {
          a.setAttribute('aria-expanded', 'false');
        }
        css_class_colore += ' nav-link dropdown-toggle ';
        // a.setAttribute('class', 'nav-link dropdown-toggle ');
      } else if (itemData.livello === 1) {
        if (array_posizioni[1] === itemData.id) {
          a.setAttribute('aria-expanded', 'true');
          css_class_colore = ' evidenzia_espansione ';
        } else {
          a.setAttribute('aria-expanded', 'false');
        }
        css_class_colore += ' nav-link nav-link-livsott dropdown-toggle ';
        // a.setAttribute('class', 'nav-link nav-link-livsott dropdown-toggle');
      }
      a.setAttribute('class', css_class_colore);
      a.setAttribute('role', 'button');
    }
    this.renderer.appendChild(item, a);
    if (itemData.sub) {
      const ul: HTMLParagraphElement = this.renderer.createElement('ul');
      if ((array_posizioni[0] === itemData.id && itemData.livello === 0) ||
        (array_posizioni[1] === itemData.id && itemData.livello === 1)) {
        ul.setAttribute('class', 'collapse show list-unstyled');
      } else {
        ul.setAttribute('class', 'collapse list-unstyled');
      }
      ul.setAttribute('id', 'menuclick_' + itemData.id);
      itemData.sub.forEach((element: any) => {
        this.renderer.appendChild(ul, this.getMenuItem(element, ul_padre, array_posizioni));
      });
      this.renderer.appendChild(item, ul);
    } else {
      const route = itemData.link[2];
      a.setAttribute('href', `${route}`);
      if ((array_posizioni[0] === itemData.id && itemData.livello === 0) || (array_posizioni[1] === itemData.id && itemData.livello === 1)
        || (array_posizioni[2] === itemData.id && itemData.livello === 2)) {
        a.setAttribute('class', this.getClassFromType(itemData.livello, 'aclickgo', 'active'));
        // emetto l'evento per il titolo della pagina, sostituito da SharedService
        // this.titoloPagina.emit(itemData.voce);
      } else {
        a.setAttribute('class', this.getClassFromType(itemData.livello, 'aclickgo'));
      }
      a.addEventListener('click', (event) => {
        event.preventDefault();

        // emetto l'evento


        // this.router.navigate([route]);

        this.router.navigate([`${route}`]);
        this.renderer.removeChild(this.div_menu.nativeElement, ul_padre);
        this.renderer.appendChild(this.div_menu.nativeElement,
          this.buildMenu(this.findPositionFromUrl(route)));
        /*if (ul_padre.getElementsByClassName('show')[1]) {
            this.renderer.removeClass(ul_padre.getElementsByClassName('show')[1], 'show');*/
        /*ul_padre.getElementsByClassName('show').forEach((element) => {
            let attr = element.getAttribute('class');
            attr = attr.replace('show', '');
            element.setAttribute('class', attr);
        });*/
        /* }*/
        // console.log(ul_padre.querySelectorAll('a[aria-expanded="true"]'));
        // a.setAttribute('class', this.getClassFromType(itemData.livello, 'aclickgo', 'active'));
      });
    }
    return item;
  }

  /**
   *
   * @param level   0,1,2
   * @param type    a, aclik, li, ul
   */
  getClassFromType(level: number, type: string, active = ''): string {
    if (level === 0) {
      if (type === 'aclickgo') {
        return 'nav-link ' + active;
      }
    } else if (level === 1) {
      if (type === 'aclickgo') {
        return 'nav-link nav-link-livsott ' + active;
      }
    } else if (level === 2) {
      if (type === 'aclickgo') {
        return 'nav-link nav-link-livsottsott ' + active;
      }
    }
    return '';
  }

  ngOnInit(): void {
    this.store.select(state => state.user.permissions).subscribe(
      (val) => {
        if (val && val.menu) {
          this.json_menu = val;
          const arrayPosCorrente = this.findPositionFromUrl(this.router.url);
          this.renderer.appendChild(this.div_menu.nativeElement, this.buildMenu(arrayPosCorrente));
          // sono nella home
          if (arrayPosCorrente[0] === -1 && arrayPosCorrente[1] === -1 && arrayPosCorrente[2] === -1) {
            // this.titoloPagina.emit('Home');
          }
        }
        // console.log('navbar menu:', val);
      }
    )
    // this.menuService.getPromiseJsonMenu().then(
    //   (data) => {
    //     // console.log('prova', data);
    //     this.json_menu = data;
    //     const arrayPosCorrente = this.findPositionFromUrl(this.router.url);
    //     this.renderer.appendChild(this.div_menu.nativeElement, this.buildMenu(arrayPosCorrente));
    //     // sono nella home
    //     if (arrayPosCorrente[0] === -1 && arrayPosCorrente[1] === -1 && arrayPosCorrente[2] === -1) {
    //       // this.titoloPagina.emit('Home');
    //     }
    //   }).catch((error) => {
    //     console.error('Errore richiesta menu, messaggio: ');
    //     console.error(error);
    //   });
  }


  ngAfterViewInit(): void {
    // array di tre elementi
    // const arrayPosCorrente = this.findPositionFromUrl(this.router.url);
    // this.renderer.appendChild(this.div_menu.nativeElement, this.buildMenu(arrayPosCorrente));
    // this.shareService.renderer_menu = this.div_menu;

  }

  /**
   * NON USATA
   * id: primo_id --> per gli ul, primovalue_id --> per gli a.
   * id: secondo_id --> per gli ul, secondovalue_id --> per gli a.
   * id: terzo_id --> per gli ul, terzovalue_id --> per gli a.
   */
  // renderMenu(): void {
  //   const _this = this;
  //   const ul: HTMLParagraphElement = _this.renderer.createElement('ul');
  //   ul.setAttribute('class', 'nav flex-column');
  //   ul.setAttribute('id', 'ul_menu_padre');
  //   // ciclo sul primo livello
  //   for (const j in MenuService.jsonMenu[0]) {
  //     // for in ha bisogno dell' if sotto, perchè potrebbe ciclare su oggetti del padre
  //     if (MenuService.jsonMenu[0].hasOwnProperty(j)) {
  //       const li: HTMLParagraphElement = _this.renderer.createElement('li');
  //       li.setAttribute('class', 'nav-item');
  //       const a: HTMLParagraphElement = _this.renderer.createElement('a');
  //       const ul_1: HTMLParagraphElement = _this.renderer.createElement('ul');
  //       const array_j = j.split(',');
  //       a.innerHTML = array_j[0];
  //       console.log(array_j[1]);
  //       a.setAttribute('id', 'primovalue_' + array_j[1]);
  //       _this.renderer.appendChild(li, a);
  //       const i = 0;
  //       for (const k in MenuService.jsonMenu[0][j][i]) {
  //         if (MenuService.jsonMenu[0][j][i].hasOwnProperty(k)) {
  //           // console.log(k);
  //           let passa_una_volta = true;
  //           // se trova rotta sul primo livello
  //           if (k === '_rotta_') {
  //             /**
  //              * MenuService.jsonMenu[0][j][i][k][0] --> abilitato: 1 no, 2 si
  //              * MenuService.jsonMenu[0][j][i][k][1] --> visibile: 1 no, 2 si
  //              * MenuService.jsonMenu[0][j][i][k][2] --> rotta
  //              */
  //             a.setAttribute('class', 'nav-link ' + _this.getStringClassAbilitatoVisibile(
  //               +MenuService.jsonMenu[0][j][i][k][0], +MenuService.jsonMenu[0][j][i][k][1]));
  //             a.setAttribute('href', '#');
  //             const route: string = MenuService.jsonMenu[0][j][i][k][2];
  //             a.addEventListener('click', (event) => {
  //               event.preventDefault();
  //               // _this.router.navigate([`${route}`]);
  //               _this.router.navigate([route]);
  //               // se c'è un elemento con la classe active, gli tolgo la classe
  //               if (ul.getElementsByClassName('active')[0]) {
  //                 _this.renderer.removeClass(ul.getElementsByClassName('active')[0], 'active');
  //               }
  //               a.setAttribute('class', 'nav-link active' + _this.getStringClassAbilitatoVisibile(
  //                 +MenuService.jsonMenu[0][j][i][k][0], +MenuService.jsonMenu[0][j][i][k][1]));
  //             });
  //             // console.log('rotta1: ' + MenuService.jsonMenu[0][j][i][k]);
  //             // cicla su i valori del secondo livello
  //           } else {
  //             // lavora sul primo livello, ma siamo nel secondo
  //             if (passa_una_volta) {
  //               passa_una_volta = false;
  //               a.setAttribute('href', '#primo_' + array_j[1]);
  //               // a.setAttribute('id', 'primovalue_' + array_j[1]);
  //               a.setAttribute('data-bs-toggle', 'collapse');
  //               // mettere una funzione che controlla
  //               a.setAttribute('aria-expanded', 'false');
  //               a.setAttribute('class', 'nav-link dropdown-toggle');
  //               a.setAttribute('role', 'button');
  //               ul_1.setAttribute('id', 'primo_' + array_j[1]);
  //               ul_1.setAttribute('class', 'collapse list-unstyled');
  //               _this.renderer.appendChild(li, ul_1);
  //             }
  //             // console.log('secondo: ' + k);
  //             const e = 0;
  //             // for (let e = 0; e < MenuService.jsonMenu[0][j][i][k].length; e++) {
  //             // <li>
  //             const li_1: HTMLParagraphElement = _this.renderer.createElement('li');
  //             const a1: HTMLParagraphElement = _this.renderer.createElement('a');
  //             const array_k = k.split(',');
  //             a1.innerHTML = array_k[0];
  //             a1.setAttribute('id', 'secondovalue_' + array_k[1]);
  //             _this.renderer.appendChild(li_1, a1);
  //             _this.renderer.appendChild(ul_1, li_1);
  //             const ul_2: HTMLParagraphElement = _this.renderer.createElement('ul');
  //             // console.log(MenuService.jsonMenu[0][j][i][k][e]);
  //             let passa_una_volta1 = true;
  //             for (const m in MenuService.jsonMenu[0][j][i][k][e]) {
  //               // leggo il contenuto della rotta
  //               if (m === '_rotta_') {
  //                 li_1.setAttribute('class', 'nav-item');
  //                 a1.setAttribute('class', 'nav-link nav-link-livsott ' + _this.getStringClassAbilitatoVisibile(
  //                   +MenuService.jsonMenu[0][j][i][k][e][m][0], +MenuService.jsonMenu[0][j][i][k][e][m][1]));
  //                 a1.setAttribute('href', '#');
  //                 const route: string = MenuService.jsonMenu[0][j][i][k][e][m][2];
  //                 a1.addEventListener('click', (event) => {
  //                   event.preventDefault();
  //                   _this.router.navigate([route]);
  //                   if (ul.getElementsByClassName('active')[0]) {
  //                     _this.renderer.removeClass(ul.getElementsByClassName('active')[0], 'active');
  //                   }
  //                   a.setAttribute('class', 'nav-link nav-link-livsott active' + _this.getStringClassAbilitatoVisibile(
  //                     +MenuService.jsonMenu[0][j][i][k][0], +MenuService.jsonMenu[0][j][i][k][1]));
  //                 });
  //                 // console.log('rotta2: ' + MenuService.jsonMenu[0][j][i][k][e][m]);
  //                 // ciclo su i valori del terzo menu
  //               } else {
  //                 if (passa_una_volta1) {
  //                   passa_una_volta1 = false;
  //                   a1.setAttribute('href', '#secondo_' + array_k[1]);
  //                   // a1.setAttribute('id', 'secondovalue_' + array_k[1]);
  //                   a1.setAttribute('data-bs-toggle', 'collapse');
  //                   // mettere una funzione che controlla
  //                   a1.setAttribute('aria-expanded', 'false');
  //                   a1.setAttribute('class', 'nav-link nav-link-livsott dropdown-toggle');
  //                   a1.setAttribute('role', 'button');
  //                   ul_2.setAttribute('id', 'secondo_' + array_k[1]);
  //                   ul_2.setAttribute('class', 'collapse list-unstyled');
  //                   _this.renderer.appendChild(li_1, ul_2);
  //                 }
  //                 const w = 0;
  //                 // for (let w = 0; w < MenuService.jsonMenu[0][j][i][k][e][m].length; w++) {
  //                 const li_2: HTMLParagraphElement = _this.renderer.createElement('li');
  //                 li_2.setAttribute('class', 'nav-item nav-link-livsottsott');
  //                 const a2: HTMLParagraphElement = _this.renderer.createElement('a');
  //                 const array_m = m.split(',');
  //                 a2.innerHTML = array_m[0];
  //                 a2.setAttribute('id', 'terzovalue_' + array_m[1]);
  //                 _this.renderer.appendChild(li_2, a2);
  //                 _this.renderer.appendChild(ul_2, li_2);
  //                 for (const g in MenuService.jsonMenu[0][j][i][k][e][m][w]) {
  //                   if (true) {
  //                     const route: string = MenuService.jsonMenu[0][j][i][k][e][m][w][g][2];
  //                     // active è da gestire
  //                     a2.setAttribute('class', 'nav-link nav-link-livsott ' +
  //                       _this.getStringClassAbilitatoVisibile(
  //                         +MenuService.jsonMenu[0][j][i][k][e][m][w][g][0],
  //                         +MenuService.jsonMenu[0][j][i][k][e][m][w][g][1]));
  //                     a2.setAttribute('href', '#');
  //                     a2.addEventListener('click', (event) => {
  //                       event.preventDefault();
  //                       _this.router.navigate([route]);
  //                       // se c'è un elemento con la classe active, gli tolgo la classe
  //                       if (ul.getElementsByClassName('active')[0]) {
  //                         _this.renderer.removeClass(ul.getElementsByClassName('active')[0], 'active');
  //                       }
  //                       a2.setAttribute('class', 'nav-link nav-link-livsott active ' +
  //                         _this.getStringClassAbilitatoVisibile(
  //                           +MenuService.jsonMenu[0][j][i][k][e][m][w][g][0],
  //                           +MenuService.jsonMenu[0][j][i][k][e][m][w][g][1]));
  //                     });
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       _this.renderer.appendChild(ul, li);
  //     }
  //   }
  //   _this.renderer.appendChild(_this.div_menu.nativeElement, ul);
  // }

  /**
   * La funzione prende due interi e restituisce una stringa con le due classi css associate
   * @param abilitato 1 no, 2 si
   * @param visibile  1 no, 2 si
   */
  getStringClassAbilitatoVisibile(abilitato: number, visibile: number): string {
    if (abilitato === 1) {
      return ' voce_disabilitata ';
    } else {
      if (visibile === 1) {
        return ' voce_nascosto ';
      }
    }
    return '';
  }
}
