<p class="form-text text-muted">Reset password per la email: {{email}}</p>
<form [formGroup]="resetPwdForm" (ngSubmit)="resetPassword()">
  <div class="form-group-lg">

    <div class="input-group input-group-lg">
      <input type="password" class="form-control font_size1"
      placeholder="Nuova password" formControlName="new_password">
    </div>
    <br>
    <!-- password -->
    <div class="input-group input-group-lg">
      <input type="password" class="form-control font_size1"
      placeholder="Conferma password" formControlName="confirm_password">
    </div>
  </div>
  <div><br></div>
  <div class="d-none d-sm-block"><br></div>
  <div class="d-flex justify-content-between align-items-center">
    <small><a href="https://wallnet.it/#contatti" target="_blank" class="card-link">Contattaci</a></small>
    <button type="submit" [disabled]="!resetPwdForm.valid" class="btn btn-primary">Reset</button>
  </div>
  <div class="d-none d-sm-block"><br></div>
</form>
