import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { User } from '../../interfaces/user';
import { PermissionsUser } from '../../interfaces/permissions-user';
import { Company } from '../../interfaces/company';

export interface UserState {
  userInfo: User | null;
  permissions: PermissionsUser | null;
  companyInfo: Company | null;
  error: string | null;
}

export const initialState: UserState = {
  userInfo: null,
  permissions: null,
  companyInfo: null,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUserInfoSuccess, (state, { userInfo }) => ({ ...state, userInfo })),
  on(UserActions.loadUserInfoFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.loadUserPermissionsSuccess, (state, { permissions }) => ({ ...state, permissions })),
  on(UserActions.loadUserPermissionsFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.loadUserCompanyInfoSuccess, (state, { companyInfo }) => ({ ...state, companyInfo })),
  on(UserActions.loadUserCompanyInfoFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.logout, state => ({ ...initialState })) // Reimposta lo stato iniziale
);
