import { Component } from '@angular/core';

@Component({
  selector: 'app-header-index',
  standalone: true,
  imports: [],
  templateUrl: './header-index.component.html',
  styleUrl: './header-index.component.scss'
})
export class HeaderIndexComponent {

}
