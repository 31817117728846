import { Component } from '@angular/core';
import { MainIndexComponent } from './main-index/main-index.component';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../services/authentication.service';
import { MainHomeComponent } from './main-home/main-home.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [MainIndexComponent, CommonModule, MainHomeComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  constructor(private authenticationService: AuthenticationService) { }
  viewMainHome(): boolean {
    return this.authenticationService.isLoggedIn;
  }
}
