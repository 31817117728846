import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ConnectHomeHeaderService } from '../../services/connect-home-header.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Store } from '@ngrx/store';
import { PageState } from '../../storage-ngrx/page/page.state';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { selectReadonly, selectTitle } from '../../storage-ngrx/page/page.selectors';
import * as PageActions from '../../storage-ngrx/page/page.actions';

@Component({
  selector: 'app-main-home',
  standalone: true,
  imports: [MatSidenavModule, MenuComponent, RouterModule, CommonModule],
  templateUrl: './main-home.component.html',
  styleUrl: './main-home.component.scss'
})
export class MainHomeComponent {
  // Prendo il componente html con id sidenav
  @ViewChild('sidenav', { static: false }) menu!: MatSidenav;

  // menu$: Observable<Menu[]>;
  titolo_pagina = 'Home';
  title$: Observable<string>;
  readonly$: Observable<boolean>;
  icona_header = '';
  // definisce se il menu è side o over
  mode_sidenav: MatDrawerMode = 'over';
  // lg
  private width_min = 991;

  constructor(private service_homeheader: ConnectHomeHeaderService, private share: SharedService,
    private authenticationService: AuthenticationService, private store: Store<{ page: PageState }>) {
    // this.authenticationService.userInfo().subscribe(
    //   (user: UserLogged) => {
    //     // console.log('user', user);
    //     this.authenticationService.saveInfoUserLogged(user);
    //   }
    // );

    this.title$ = this.store.select(selectTitle);
    this.readonly$ = this.store.select(selectReadonly);
  }

  /*setTitlePage(titolo: string): void {
      this.titolo_pagina = titolo;
  }*/

  /* il metodo imposta il tipo di menù  */
  menuSideOver(window_width: number): void {
    if (window_width > this.width_min) {
      this.mode_sidenav = 'side';
    } else {
      this.mode_sidenav = 'over';
    }
  }

  /*  Apre o chiude il menu */
  menuVistaSiNo(window_width: number): void {
    if (window_width > this.width_min) {
      this.menu.open();
    } else {
      this.menu.close();
    }
  }

  // when resize the window
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.menuSideOver(window.innerWidth);
    this.menuVistaSiNo(window.innerWidth);

  }

  ngOnInit(): void {
    // console.log('-----MainHome');
    this.menuSideOver(window.innerWidth);
    this.store.dispatch(PageActions.setTitle({ title: 'Home' }));
    // this.share.titolo_pagina.subscribe(
    //   (titolo) => {
    //     this.titolo_pagina = titolo;
    //   });

  }

  ngAfterViewInit(): void {
    // console.log('MainHomeComponent: ngAfterViewInit ' + this.menu + '___________');
    // metto l'id nel servizio per usarlo nell'header, quando la vista è stata costruita
    if (this.menu) {
      this.service_homeheader.setSidenav(this.menu);
    }
  }

}
