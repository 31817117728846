<nav
  class="navbar navbar-white bg-theme_logoscuro bg-theme_ flex-nowrap fixed-top mt-0 pt-0 mb-0 pb-0 justify-content-between">
  <div class="d-flex ">
    <a class="navbar-brand" href="#">
      <!-- <app-icons [tipo_icona]="'2'" [image]="'icona_2.png'" [width_value]="'30'" [height_value]="'30'" [alt_value]="'Logo azienda'" ></app-icons>-->
    </a>
  </div>
  <!--<div class="d-flex ">
    <a class="navbar-brand d-none" href="#">

        <img src="../../../assets/images/logo_creabit_w.png" width="104" height="40" alt="">
    </a>
  </div> -->
</nav>
