import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, CommonModule, MatCheckboxModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  // @ViewChild('f', { static: false }) form: NgForm;
  rememberme = false;
  email_input = '';
  pwd_input = '';
  constructor(private authenticationService: AuthenticationService) {
  }


  login(form: NgForm) {
    if (!form.valid) {
      // console.log('email error');

    } else {
      const email = form.value.email;
      const password = form.value.password;
      this.authenticationService.loginServer(email, password, this.rememberme);
    }
  }
  forgotPassword(email: string): void {
    if (email) {
      this.authenticationService.forgotPasswordServer(email);
    }
  }

  onEnter(event: any): void {
    // Impedisci la propagazione dell'evento "Enter"
    event.preventDefault();
  }
}
