import { HttpInterceptorFn, HttpXsrfTokenExtractor } from '@angular/common/http';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const cookieService = inject(CookieService);
  const token = cookieService.get('XSRF-TOKEN');
  // URL da escludere
  const excludedUrls = ['https://barcode.wallnet.it/decode'];
  // Controlla se l'URL corrisponde a quelli da escludere
  if (excludedUrls.some(url => req.url.startsWith(url))) {
    // Lascia la richiesta invariata
    return next(req);
  }
  //console.log('token', token);
  const newReq = req.clone({
    setHeaders: {
      Accept: 'application/json', // Aggiungi l'header desiderato
      'X-XSRF-TOKEN': token,
    },
    withCredentials: true, // Abilita l'invio di credenziali
  });
  //console.log('headers:', req.headers);
  return next(newReq);
};
