import { Component } from '@angular/core';
import { CalendarEvent, CalendarModule, CalendarView, DateAdapter, DAYS_OF_WEEK } from 'angular-calendar';
import { map, Observable } from 'rxjs';
import { CalendarEventInterface } from './interfaces/calendar-event-interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { Connect } from '../../../../classes/connect';
import { endOfDay, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { PopupDialogAfterClose } from '../../../../interfaces/popup-dialog-after-close';
import { EventComponent } from './event/event.component';
import { CardComponent } from './card/card.component';
import { EventInterface } from './interfaces/event-interface';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter as DateAdapterCore, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { it } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

interface CalendarioTipo {
  id: number;
  descrizione: string;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  violet: {
    primary: '#9306b2',
    secondary: '#f8dffe',
  },
};

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    CommonModule,
    CalendarModule,
    MatIconModule,
    MatButtonModule
  ],
  providers:
    [
      { provide: MAT_DATE_LOCALE, useValue: it },
      { provide: DateAdapterCore, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent {
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  locale = 'it';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  dayStartHour = 7;
  dayEndHour = 21;
  arrayCalendarioTipo: CalendarioTipo[] = [];
  CalendarView = CalendarView;
  activeDayIsOpen = false;
  events$!: Observable<CalendarEvent<{ evento: CalendarEventInterface }>[]>;
  eventoModifica!: CalendarEventInterface;
  dialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog,
    private connectService: ConnectServerService) {

    this.dialogConfig.disableClose = false;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.data = null;
    this.dialogConfig.panelClass = '';
  }

  ngOnInit(): void {
    this.connectService.getRequestLara<CalendarioTipo>(
      Connect.urlServerLaraApi, 'calendar/typeCalendar', {})
      .subscribe(
        (arrayCalendarioTipo: CalendarioTipo[]) => {
          this.arrayCalendarioTipo = arrayCalendarioTipo;
          // this.arrayCalendarioTipo = [];
          this.fetchEvents();
        }
      );
  }

  /**
   * Per ogni evento
   * @param evento
   */
  private elaboraEvento(evento: CalendarEventInterface): CalendarEvent {
    const obj: CalendarEvent = {
      start: new Date(evento.dataini),
      title: ''
    };

    const descrizioneTipo = this.arrayCalendarioTipo.find(x => x.id == evento.rifidtipo)?.descrizione;
    obj.color = colors.blue;
    //console.log(descrizioneTipo);
    if (descrizioneTipo == 'Sala') {
      obj.color = colors.violet;
    } else if (descrizioneTipo == 'Promemoria') {
      obj.color = colors.yellow;
    } else if (descrizioneTipo == 'Altro') {
      obj.color = colors.red;
    } else if (descrizioneTipo == 'Corso') {
      const colore: any = {
        personalizzato: {
          primary: `#${evento.color_hex}`,
          secondary: `#${evento.color_hex}`,
        }
      };;
      obj.color = colore.personalizzato;
    }
    if (evento.tuttoilgiorno) {
      obj.allDay = true;
      obj.start = new Date(evento.dataini);
    } else {
      obj.allDay = false;
      const arr_dataini = String(evento.dataini).split('-');
      if (evento && evento.oraini && evento.orafine) {
        const arr_oraini = evento.oraini.split(':');
        obj.start = new Date(+arr_dataini[0], +arr_dataini[1] - 1, +arr_dataini[2], +arr_oraini[0], +arr_oraini[1], +arr_oraini[2]);
        const arr_datafine = String(evento.datafine).split('-');
        const arr_orafine = evento.orafine.split(':');
        obj.end = new Date(+arr_datafine[0], +arr_datafine[1] - 1, +arr_datafine[2], +arr_orafine[0], +arr_orafine[1], +arr_orafine[2]);
        obj.resizable = {
          beforeStart: false,
          afterEnd: false
        };
      }

    }
    obj.title = evento.titolo;
    obj.id = evento.id;
    obj.meta = {
      evento
    };
    return obj;
  }
  /*private startW(){
      return startOfWeek(this.viewDate, {weekStartsOn: 1});
  }
  private endW(){
      return endOfWeek(new Date().getTime(), {weekStartsOn: 1});
  }*/
  /**
   * La funzione si collega al server ed in base alla data carica gli eventi
   */
  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    this.events$ = this.connectService.getRequestLara(Connect.urlServerLaraApi, 'calendar/listCalendar',
      {
        startdate: format(getStart(this.viewDate), 'yyyy-MM-dd'),
        enddate: format(getEnd(this.viewDate), 'yyyy-MM-dd')
      }).pipe(
        map((results) => {
          //console.log(results);
          return results.map((evento: any) => {
            //console.log(evento);
            /* evento.anagrafica = {
                 idanagrafica: evento.rifanagrafica,
                 ragionesociale: evento.contattonome
             };*/
            return this.elaboraEvento(evento);
          });
        })
      );
  }

  /**
   * Chiamata quando si clicca su mese, settimana o giorno
   * @param view
   */
  setView(view: CalendarView): void {
    this.view = view;
    this.fetchEvents();
  }

  /**
   * Quando clicco sul giorno nella visualizzazione a mese
   * @param date
   * @param events
   */
  dayClicked({
    date, events,
  }: {
    date: Date;
    events: CalendarEvent<{ evento: EventInterface }>[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  private openDialog(val: any) {
    this.dialogConfig.data = val;
    const dialogRef = this.dialog.open(EventComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((reload: PopupDialogAfterClose) => {
      if (reload && reload.avanti) {
        this.fetchEvents();
      }
    });
  }

  /**
   * La funzione aggiunge un nuovo evento
   */
  addEvento(): void {
    // const dataNow = this.datePipe.transform(day, 'dd/MM/yyyy');

    // dialogConfig.backdropClass = 'pippo';
    this.openDialog(null);

  }

  /**
   * La funzione modifica un evento esistente
   */
  /*openEvent(event: Evento): void {
     this.openDialog(event);
  }*/

  /**
   * Viene chiamato quando si clicca su un evento, fa vedere la card
   * @param event
   */
  handleEvent(event: CalendarEvent): void {

    const obj_evento: CalendarEventInterface = event.meta.evento;
    if (obj_evento.id > 0) {
      const dialogRef = this.dialog.open(CardComponent, { data: obj_evento.id });
      dialogRef.afterClosed().subscribe(
        (val) => {
          // se clicco modifica apro dialog
          if (val) {
            this.openDialog(obj_evento.id);
          }
        }
      );
    }
    // this.modal.open(this.modalContent, { size: 'lg' });
  }
}
