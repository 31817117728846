import { Injectable, Renderer2 } from '@angular/core';
import { IconsComponent } from '../../components/icons/icons.component';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor() { }

  /**
     * Cambia l'icona da vuota a piena
     * @param renderer
     * @param element
     */
  changeIconHomeHeader(renderer: Renderer2, element: IconsComponent): void {
    element.fontsetValue = 'material-icons';
}
}
