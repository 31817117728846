import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalComponent } from '../popup/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PopupDialogErrorService {

  constructor(private dialog: MatDialog, private router: Router) { }

  public popUpDialog(error: any, errorMessage: string): void {
    // console.log('dentro error status 1', this.dialog.openDialogs);
    // console.log('dentro error status 1', !this.dialog.openDialogs);
    // console.log('dentro error status 2', this.dialog.openDialogs.length);
    // console.log('dentro error status 2', !this.dialog.openDialogs.length);
    if (!this.dialog.openDialogs || (!this.dialog.openDialogs.length || this.dialog.openDialogs.length<2)) {
    let tipo = 'error';
    let titolo = 'Errore';
    if (error.status && error.status >= 511 && error.status <= 520) {
      tipo = 'warning';
      titolo = error.error.titolo;
    }
    const dialogRef = this.dialog.open(ModalComponent, {
      panelClass: 'modal_popup',
      data: {
        tipo: tipo.toString(),
        titolo: titolo.toString(),
        error_code: error.status ? error.status : '',
        error_text: error.error ? error.error : '',
        contenuto: {
          descrizione: errorMessage
        },
        pagina: (error.error && error.error.pagina) ? error.error.pagina : '',
        dialogResultChiudi: (error.error && error.error.dialogResultChiudi) ? error.error.dialogResultChiudi : '',
        dialogResultGo: (error.error && error.error.dialogResultGo) ? error.error.dialogResultGo : '',
        dati: (error.error && error.error.valore) ? error.error.valore : '',
        disableClose: (error.error && error.error.disableClose) ? error.error.disableClose : ''
      }
    });
    dialogRef.afterClosed().subscribe(
      (risposta: any) => {
        // console.log(risposta);
        if (risposta.avanti) {
          if (risposta.button_who === 'close') {
            if (error.error.valore.array_path_close.length > 0) {
              if (error.error.valore.queryParams_close) {
                this.router.navigate(error.error.valore.array_path_close,
                  { queryParams: error.error.valore.queryParams_close });
                /*this.router.navigate(['/anagrafica/socio', this.tipo_richiesta, 'aggiungi'],
{queryParams: {anno: this.anno_richiesta, rifidanno: this.rifidanno_richiesta}}
);*/
              } else {
                this.router.navigate(error.error.valore.array_path_close);
              }
            }
          } else if (risposta.button_who === 'go') {
            // console.log('vai');
            if (error.error.valore.array_path_go.length > 0) {
              if (error.error.valore.queryParams_go) {
                this.router.navigate(error.error.valore.array_path_go, { queryParams: error.error.valore.queryParams_go });
                /*this.router.navigate(['/anagrafica/socio', this.tipo_richiesta, 'aggiungi'],
{queryParams: {anno: this.anno_richiesta, rifidanno: this.rifidanno_richiesta}}
);*/
              } else {
                this.router.navigate(error.error.valore.array_path_go);
              }
            }
          }
        }
        /*if (risposta) {
            this.router.navigate([error.error.valore.url, error.error.valore.id]);
        }*/
      });
    }
  }


  public getTimeoutForRequest(request: HttpRequest<any>): number {
    // Puoi implementare la logica per ottenere il timeout in base al tipo di richiesta.
    // Ad esempio, puoi controllare il metodo della richiesta (GET, POST, ecc.) o l'URL e restituire il timeout appropriato.
    // console.log(request.method);
    if (request.method === 'GET') {
      return 40000; // Timeout di 40 secondi per le richieste GET
    } else if (request.method === 'POST') {
      // return 60000; // Timeout di 60 secondi per le richieste POST
      return 60000; // Timeout di 60 secondi per le richieste POST
    } else {
      return 30000; // Timeout di 20 secondi per gli altri tipi di richieste (PUT, DELETE, ecc.)
    }
  }
}
