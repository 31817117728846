<!-- mat-icon -->
<mat-icon *ngIf="1==tipoIcona" [matBadge]="matBadge_value"
          [matBadgeColor]="matBadgeColor_value" [matBadgeSize]="matBadgeSize_value"
          [ngClass]="classValue" [fontSet]="fontsetValue" [attr.aria-label]="arialabelValue"
          [attr.aria-hidden]="ariahiddenValue" [attr.alt]="alt_value"
          [attr.id]="id_value">{{icon}}
</mat-icon>

<!-- img -->
<img *ngIf="2==tipoIcona" [attr.src]="value_src" [attr.width]="widthValue" [attr.height]="heightValue"
     [attr.alt]="alt_value" [ngClass]="classValue">

<!-- SVG -->
<mat-icon *ngIf="3==tipoIcona" [svgIcon]="svgIconValue" [attr.aria-hidden]="ariahiddenValue"
          [attr.aria-label]="arialabelValue" [ngClass]="classValue"></mat-icon>
