// company.actions.ts
import { createAction, props } from '@ngrx/store';
import { CompanyDefaultValues } from '../../interfaces/company-default-values';

export const loadCompany = createAction('[Company] Load Company');
export const loadCompanySuccess = createAction(
  '[Company] Load Company Success',
  props<{ company: CompanyDefaultValues }>()
);
export const loadCompanyFailure = createAction(
  '[Company] Load Company Failure',
  props<{ error: any }>()
);
