import { Injectable } from '@angular/core';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { Connect } from '../../../../classes/connect';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

    static jsonMenu = [];

  constructor(private connectServerService: ConnectServerService) { }

  /**
    * Server con Laravel
    */
  async getPromiseJsonMenu(): Promise<any> {
    return await this.connectServerService.getRequestLaraAsync(Connect.urlServerLaraApi, 'settings/renderMenu',
      {});
  }
}
