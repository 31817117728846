<div *ngIf="!viewMainHome()" class="container-fluid bg-dark">
  <div class="row pt-2 ms-3 me-3 ">
      <div class="d-none d-md-flex col-md-4 align-items-center">
          <a class="navbar-brand" href="#">
              <app-icons [tipoIcona]="2" [ariahiddenValue]="'false'" class="align-self-center"
                         [srcIcon]="'loghi/wallnet_w.png'"></app-icons>
          </a>
      </div>
      <div class="col-12 col-sm-6 col-md-4   pb-0 mb-0">
          <div class="card-body  mt-0 pt-0 mb-0 pb-0">
              <h6 class="card-title mb-0 pb-0 text-white">CHI SIAMO</h6>
              <p class="card-text font-weight-light text-light line_h_12 mb-0 pb-0">
                  <small>L'informatica al tuo servizio.</small>
              </p>
              <hr class="bg-light mr-5 mt-0 pt-0 mb-2">
              <p class="line_h_12 text-light">
                  <small class="font-weight-light">
                      Wallnet s.n.c.<br>
                      Piazza Dante, 25<br>
                      50032, Borgo San Lorenzo, FI<br>
                      C.F. e P.IVA 06995470488<br>
                      <a class="a_clean" target="_blank" href="https://www.wallnet.it">www.wallnet.it</a>
                  </small>
              </p>
          </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
              <h6 class="card-title mb-0 pb-0 text-light">CONTATTACI</h6>
              <p class="card-text font-weight-light text-white line_h_12 mb-0 pb-0 ">
                  <small>Il nostro servizio clienti è pronto a risponderti.</small>
              </p>
              <hr class="bg-white mr-5  mt-0 pt-0 mb-2">
              <p class="line_h_12 text-light">
                  <small class="font-weight-light">
                      Tel. 055 0540978<br>
                      Lun / Ven<br>
                      08.30 - 13.00 <br>
                      14.00 - 18.00 <br>
                      <a class="a_clean" target="_blank" href="https://www.facebook.com/wallnet.BSL/">
                          <mat-icon class=" " aria-hidden="false" aria-label="Wallnet">facebook</mat-icon>
                      </a>
                  </small>
              </p>
          </div>
      </div>
  </div>
</div>
