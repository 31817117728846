import { createReducer, on } from '@ngrx/store';
import * as CompanyActions from './company.actions';
import { CompanyDefaultValues } from '../../interfaces/company-default-values';

export interface CompanyState {
  company: CompanyDefaultValues | null;
  error: any;
}

export const initialState: CompanyState = {
  company: null,
  error: null
};

export const companyReducer = createReducer(
  initialState,
  on(CompanyActions.loadCompanySuccess, (state, { company }) => ({
    ...state,
    company,
    error: null
  })),
  on(CompanyActions.loadCompanyFailure, (state, { error }) => ({
    ...state,
    error
  }))
);
