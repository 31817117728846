import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconsComponent } from '../components/icons/icons.component';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, MatIconModule, IconsComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  constructor(private authenticationService: AuthenticationService) {

  }

  viewMainHome(): boolean {
    return this.authenticationService.isLoggedIn;
  }
}
