<mat-sidenav-container class="container-fluid mt-5  ml-0 px-0 bg-white container_creato">
  <mat-sidenav #sidenav [mode]="mode_sidenav" opened class="bg-theme_logoscuro sidebar">
      <!-- mode: over o side-->
      <!--<app-menu (titoloPagina)="setTitlePage($event)"></app-menu>-->
      <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="px-3 bg-light ">
      <div class="d-flex justify-content-between flex-wrap
      flex-lg-nowrap align-items-center pt-4 pb-0 mb-3 border-bottom ">
          <h2 class="mb-1">{{title$ | async}}</h2>

          <div class="btn-toolbar mb-2 mb-md-0 bg-info">
              <!--<div class="btn-group mr-2">
                  <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
                  <button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
              </div>
              <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
                  <span data-feather="calendar"></span>
                  This week
              </button>
              <div>
                  <a class="  text-center " href="#">
                      <mat-icon class=" " aria-hidden="false" aria-label="Info icon" >info</mat-icon>
                  </a>
              </div>-->
          </div>
      </div>
      <div class="pb-5">
          <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>
