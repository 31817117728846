<header>
  <app-header></app-header>
</header>
<main class="altezza_home bg-theme_logoscuro">
  <app-main></app-main>
  <ngx-spinner type="square-jelly-box">
  </ngx-spinner>
</main>
<footer>
  <app-footer></app-footer>
</footer>
