<div class="width_card">
  <h2 mat-dialog-title>{{obj_infoevento?.descrizionetipo}}</h2>
  <mat-dialog-content class="mat-typography">
      <h3>{{obj_infoevento?.titolo}}</h3>
      <p>{{obj_infoevento?.descrizione}}</p>

      <p>{{obj_infoevento?.dataini | date: 'dd/MM/yyyy'}}
          <span *ngIf="obj_infoevento && obj_infoevento.dataini != obj_infoevento.datafine">
              {{obj_infoevento.datafine | date: 'dd/MM/yyyy'}}
          </span>
      </p>

      <h3 *ngIf="obj_infoevento && obj_infoevento?.tuttoilgiorno">Tutto il giorno</h3>
      <h3 *ngIf="!obj_infoevento?.tuttoilgiorno">{{obj_infoevento?.oraini}} - {{obj_infoevento?.orafine}}</h3>
      <p *ngIf="obj_infoevento && obj_infoevento.denominazione && obj_infoevento.denominazione.length > 0">
          <span class="fw-bold text-primary">Azienda: </span>{{obj_infoevento?.denominazione}}
      </p>
      <p *ngIf="obj_infoevento && obj_infoevento.sede && obj_infoevento.sede.length > 0">
          <span class="fw-light text-primary">Sede: </span>{{obj_infoevento?.sede}}</p>
      <p *ngIf="obj_infoevento && obj_infoevento.contatto && obj_infoevento.contatto.length > 0">
          <span class="fw-light text-primary">Contatto: </span>{{obj_infoevento.contatto}}</p>
      <p *ngIf="obj_infoevento && obj_infoevento.descrizionetipo == 'Corso'">
          <span class="fw-bold text-primary">Corsisti: </span> <span *ngFor="let corsista of obj_infoevento.corsisti">
              {{corsista.nome}} {{corsista.cognome}} -
          </span>
      </p>
      <span *ngIf="obj_infoevento && obj_infoevento.descrizionetipo != 'Corso' && obj_infoevento.descrizionetipo != 'Altro'">
          <p><span *ngFor="let utente of obj_infoevento.utenti">{{utente.name}} </span></p>
      </span>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Chiudi</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Modifica</button>
  </mat-dialog-actions>
</div>
