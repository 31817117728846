import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIcon, MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import {MatBadgeModule, MatBadgeSize} from '@angular/material/badge';
import { ThemePalette } from '@angular/material/core';


@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatBadgeModule],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss'
})
export class IconsComponent implements OnChanges, OnInit, AfterViewInit{
  // @Input() value_class;
  @Input() icon: string = '';
  @Input() classValue: string = '';
  @Input() arialabelValue: string = '';
  @Input() ariahiddenValue: string = '';
  @Input() fontsetValue: string = '';
  @Input() id_value: string = '';
  // di default mat icon
  @Input() tipoIcona = 1;
  @Input() widthValue: string = '';
  @Input() heightValue: string = '';
  @Input() alt_value: string = '';
  @Input() matBadge_value: number | null = null;
  @Input() matBadgeColor_value : ThemePalette = undefined;
  @Input() matBadgeSize_value : MatBadgeSize = 'medium';
  src_base = 'assets/images/';
  value_src = '';
  @Input() svgIconValue: string = '';
  @Input() srcIcon: string | null = null;

  matbadgePresente = false;

  constructor(private serviceShare: SharedService, private elRef: ElementRef,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    if (this.svgIconValue) {
      iconRegistry.addSvgIcon(
        this.svgIconValue,
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/' + this.srcIcon));
    }
  }

  ngOnInit(): void {
    // console.log('icon: ' + this.icon);
    this.value_src = this.src_base + this.srcIcon;
    // console.log('image: ' + this.image);
    if (this.matBadge_value) {
      this.matbadgePresente = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['srcIcon'] && changes['srcIcon'].currentValue.length > 0) {
      this.value_src = this.src_base + this.srcIcon;
    }
  }
  ngAfterViewInit(): void {
    if (this.id_value === 'id_icon_home') {
      const OBJ_HOME: IconsComponent = this;
      const a: MatIcon = this.elRef.nativeElement.querySelector('#id_icon_home');
      this.serviceShare.renderer_fontset_iconhome = OBJ_HOME;
    }
  }
}
