<div class="width_card" >
  <h2 mat-dialog-title>{{titoloDialog}}</h2>
  <mat-dialog-content>
      <form class="container-xxl" [formGroup]="optionsForm" *ngIf="!bool_error">
          <div class="row">
              <div class="col-12">
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="rifidtipo"
                      (change)="eventoTipo($event.value)">
                      <!--<mat-button-toggle *ngFor="let tipo of (calendariotipoAll$ | async)"
                                 value="{{tipo.id}}">{{tipo.descrizione}}
              </mat-button-toggle>-->
                      <mat-button-toggle *ngFor="let tipo of arrayCalendarioTipo" [value]="tipo.id">
                          {{tipo.descrizione}}
                      </mat-button-toggle>
                      <!--<mat-button-toggle value="1"
                                 matTooltip="Il promemoria se non archiviato si ripete nei giorni successivi.">
                  Promemoria
              </mat-button-toggle>-->
                  </mat-button-toggle-group>
              </div>
          </div>
          <mat-form-field *ngIf="false">
              <mat-label>ID</mat-label>
              <input matInput formControlName="id">

          </mat-form-field>
          <div class="row mt-2">
              <mat-form-field *ngIf="!selectSala && !selectCorso" hintLabel="Massimo 40 caratteri" class="col-12">
                  <mat-label>Titolo</mat-label>
                  <input matInput #input maxlength="40" placeholder="{{str_placeholdertitolo}}"
                      formControlName="titolo">
                  <mat-hint align="end">{{input.value.length || 0}}/40</mat-hint>
              </mat-form-field>
          </div>
          <div class="row">
              <mat-form-field *ngIf="selectSala" class="col-12">
                  <mat-label>Sala</mat-label>
                  <mat-select formControlName="rifidsala">
                      <mat-option [value]="0">Seleziona sala</mat-option>
                      <mat-option *ngFor="let sala of ( saleAll$ | async)" [value]="sala.id">
                          {{sala.nome}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="row">
              <mat-form-field *ngIf="selectCorso" class="col-12">
                  <mat-label>Corso</mat-label>
                  <mat-select formControlName="rifidcorsoformazione">
                      <mat-option [value]="0">Seleziona corso</mat-option>
                      <mat-option *ngFor="let corso of ( corsiAll$ | async)" [value]="corso.id">
                          {{corso.nome}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="row">
              <mat-form-field class="col-12">
                  <mat-label>Descrizione</mat-label>
                  <textarea matInput placeholder="..." formControlName="descrizione"></textarea>
              </mat-form-field>
          </div>
          <div class="row mb-2">
              <section class="col-12 mat-form-field">
                  <span class="row">
                      <!--<mat-label class="col-6 col-sm-8"></mat-label>-->
                      <mat-radio-group class="col-12" formControlName="chi">
                          <mat-radio-button [value]="0" class=" me-2">
                              Esterno
                          </mat-radio-button>
                          <mat-radio-button [value]="1" class=" me-2">
                              Interno
                          </mat-radio-button>
                          <mat-radio-button [value]="2">
                              Non definito
                          </mat-radio-button>
                      </mat-radio-group>
                  </span>
              </section>
          </div>
          <div class="row" *ngIf="optionsForm.controls['chi'].value == 0">
              <mat-form-field class="col-12">
                  <input type="search" placeholder="Azienda" aria-label="Azienda" matInput
                      formControlName="anagrafica_cliforprodati" [matAutocomplete]="anagrafica_cliforprodati">
                  <mat-autocomplete autoActiveFirstOption #anagrafica_cliforprodati="matAutocomplete"
                      [displayWith]="displayFnCliForPro" (optionSelected)="anagraficaCliForProSelect($event)">
                      <mat-option *ngFor="let option of filteredAnacliforpro$ | async" [value]="option">
                          {{option.denominazione}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
              <mat-form-field *ngIf="optionsForm.controls['anagrafica_cliforprodati'].value" class="col-12">
                  <mat-label>Sede</mat-label>
                  <mat-select formControlName="rifidanacliforprosedi">
                      <mat-option [value]="0">
                          Non selezionata
                      </mat-option>
                      <mat-option *ngFor="let sede of (sediAnacliforpro$ | async )" [value]="sede.id">
                          {{sede.indirizzo}}, {{sede.numero}} - {{sede.comune}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="!selectCorso && optionsForm.controls['anagrafica_cliforprodati'].value" class="col-12">
                  <mat-label>Contatto</mat-label>
                  <mat-select formControlName="rifidanacliforprocontatti">
                      <mat-option [value]="0">
                          Non selezionato
                      </mat-option>
                      <mat-option *ngFor="let contatto of (contattiAnacliforpro$ | async )" [value]="contatto.id">
                          {{contatto.nome}} {{contatto.cognome}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="selectCorso && optionsForm.controls['anagrafica_cliforprodati'].value" class="col-12">
                  <mat-label>Corsisti</mat-label>
                  <mat-select formControlName="corsisti" multiple>
                      <mat-option *ngFor="let corsista of arrayCorsisti" [value]="corsista.id">
                          {{corsista.nome}} {{corsista.cognome}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

          </div>
          <div class="row" *ngIf="optionsForm.controls['chi'].value == 1">
              <mat-form-field class="col-12">
                  <input type="search" placeholder="Persona interna" aria-label="Persona interna" matInput
                      formControlName="anagrafica" [matAutocomplete]="anagrafica">
                  <mat-autocomplete autoActiveFirstOption #anagrafica="matAutocomplete"
                      [displayWith]="displayFnAnagrafica">
                      <mat-option *ngFor="let option of filteredAnagrafica$ | async" [value]="option">
                          {{option.denominazione}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="col-12">
                  <mat-label>Sede</mat-label>
                  <mat-select formControlName="rifidaziendasedi">
                      <mat-option [value]="0">
                          Non selezionata
                      </mat-option>
                      <mat-option *ngFor="let sede of (sediAzienda$ | async )" [value]="sede.id">
                          {{sede.address}}, {{sede.house_number}} - {{sede.municipality}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

          </div>
          <!--
      <mat-form-field class="w-100">
          <mat-label>Luogo</mat-label>
          <mat-select formControlName="luogonome">
              <mat-option value="Sede Artim">Sede Artim</mat-option>
              <mat-option value="Destinazione diversa">Destinazione diversa</mat-option>
              <mat-option *ngFor="let luogo of (luogoAll$ | async)" value="{{luogo.indirizzo}}">
                  {{luogo.indirizzo}}
              </mat-option>
          </mat-select>
      </mat-form-field>-->
          <div *ngIf="!(this.selectCorso || this.selectAltro)" class="row">
              <mat-form-field class="col-12">
                  <mat-label>Data</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Inizio" formControlName="dataini">
                      <input matEndDate placeholder="Fine" formControlName="datafine">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
          </div>
          <div *ngIf="this.selectCorso || this.selectAltro" class="row">
              <mat-form-field class="col-12">
                  <mat-label>Data</mat-label>
                      <input matInput [matDatepicker]="dataini" formControlName="dataini">
                      <input class="d-none" matInput [matDatepicker]="dataini" formControlName="datafine">
                  <mat-hint>GG/MM/AAAA</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="dataini"></mat-datepicker-toggle>
                  <mat-datepicker #dataini></mat-datepicker>
              </mat-form-field>

          </div>
          <div class="row">
              <div class="col-12">
                  <!--<mat-slide-toggle [checked]="toggleCheck" (change)="eventoTuttoGiorno($event)"-->
                  <mat-slide-toggle (change)="eventoTuttoGiorno($event)" formControlName="tuttoilgiorno">
                      Tutto il giorno
                  </mat-slide-toggle>
              </div>
          </div>
          <div class="row">
              <mat-form-field class="col-6">
                  <mat-label>Ora inizio</mat-label>
                  <div>
                      <input matInput [ngxTimepicker]="pickerorainit" [min]="'07:00 am'" [max]="'9:00 pm'"
                          [format]="24" readonly formControlName="oraini">
                      <ngx-material-timepicker #pickerorainit [enableKeyboardInput]="true" [minutesGap]="15"
                          [ngxMaterialTimepickerTheme]="darkTheme"></ngx-material-timepicker>
                  </div>
              </mat-form-field>
              <mat-form-field class="col-6">
                  <mat-label>Ora fine</mat-label>
                  <div>
                      <input matInput [ngxTimepicker]="pickerorafine" [min]="'07:00 am'" [max]="'9:00 pm'"
                          [format]="24" readonly formControlName="orafine">
                      <ngx-material-timepicker #pickerorafine [enableKeyboardInput]="true" [minutesGap]="15"
                          [ngxMaterialTimepickerTheme]="darkTheme"></ngx-material-timepicker>
                  </div>
              </mat-form-field>
          </div>
          <div class="row" [ngClass]="this.selectCorso || this.selectAltro ? 'd-none' : ''">
              <mat-form-field class="col-12">
                  <mat-label>Utenti</mat-label>
                  <mat-select formControlName="utenti" multiple>
                      <mat-option *ngFor="let utente of arrayUtenti" [value]="utente.id">
                          {{utente.nickname}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
      </form>
      <div *ngIf="bool_error">
          Non hai i permessi per aggiungere nessun evento.
      </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="nuovoEvento" align="end">
      <button mat-raised-button mat-dialog-close color="primary">Annulla</button>
      <button *ngIf="!bool_error" mat-raised-button [disabled]="false" cdkFocusInitial color="primary" (click)="saveEvento()">Aggiungi
      </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="!nuovoEvento" align="end">
      <button mat-raised-button [disabled]="optionsForm.invalid" cdkFocusInitial color="primary"
          (click)="deleteEvento()">Elimina
      </button>
      <button mat-raised-button mat-dialog-close color="primary">Annulla</button>
      <button mat-raised-button [disabled]="optionsForm.invalid" cdkFocusInitial color="primary"
          (click)="updateEvento()">Modifica
      </button>
  </mat-dialog-actions>
</div>
