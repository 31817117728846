import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError, timeout } from 'rxjs';
import { PopupDialogErrorService } from '../services/popup-dialog-error.service';
import { PopupService } from '../services/popup.service';
import { ApiResponse } from '../interfaces/api-response';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const popupDialogService = inject(PopupDialogErrorService);
  const popupService = inject(PopupService);
  return next(req).pipe(
    timeout({ each: popupDialogService.getTimeoutForRequest(req), with: () => throwError(() => new Error('Timeout della richiesta')) }),
    catchError((error: any) => {

      // console.log('intercetto errore: ', error);
      let errorMessage = 'Errore generico!';
      if (error instanceof HttpErrorResponse) {
        // console.log('error',error);
        // Gestisci gli errori HTTP
        if (error.status >= 400 && error.status < 500) {
          // Gestisci gli errori client (codice di stato nella fascia 4xx)
          // console.error('Errore client nell\'interceptor:', error);
          if (error.status == 401 || error.status == 419) {
            errorMessage = 'Sessione scaduta. Effettua nuovamente l\'accesso';
          } else if (error.status == 403) {
            errorMessage = 'Utente non autorizzato. Accesso negato.';
          } else if (error.status == 422) {
            errorMessage = error.error.message;
          } else if (error.status == 429) {
            errorMessage = 'Effettuate troppe richieste al server. Accesso negato.';
          }
        } else if (error.status >= 500 && error.status < 600) {
          // Gestisci gli errori server (codice di stato nella fascia 5xx)
          // console.error('Errore server nell\'interceptor:', error);
          if (error.status == 500) {
            if (error.error && error.error.message == 'The token has been blacklisted') {
              errorMessage = 'Sessione scaduta. Effettua nuovamente l\'accesso.';
            } else if (error.error && error.error.message == 'Token has expired and can no longer be refreshed') {
              errorMessage = 'Sessione scaduta! Effettua nuovamente l\'accesso.';
            }
          }
          if (error.status > 510 && error.status < 530) {
            if(error.status == 518 || error.status == 523 || error.status == 522){

            }else{
              errorMessage = error.error.messaggio;
            }
            if (error.status == 521 && error.error instanceof Blob) {
              errorMessage = 'Impossibile creare il file! Riprova o contatta Wallnet.';
            }
          }
        } else {
          // Gestisci altri tipi di errori HTTP
          console.error('Errore HTTP nell\'interceptor:', error);
        }

      } else if (error instanceof Error) {
        // Gestisci l'errore di timeout qui, ad esempio mostrando un messaggio all'utente o effettuando qualche altra azione
        if (error.message === 'Timeout della richiesta') {
          errorMessage = 'Timeout della richiesta!';
          // console.error('error.name:', error.name);
          // console.error('error.message:', error.message);
          console.error('Timeout della richiesta:', error);
        } else {
          console.error('Errore generico nell\'interceptor:', error);
        }
      } else {
        // Altrimenti, gestisci gli altri tipi di errori come nel precedente esempio
        console.error('Errore imprevisto nell\'interceptor:', error);
      }

      // mi serve per non aprire più volte lo stesso errore di sessione scaduta
      // qui si gestisce anche le risposte con BLOB (FILE)
      if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
        error.error.text().then(
          (val) => {
            // console.log('dentro promise: ', val);
            const message = JSON.parse(val).message;
            // console.log('Messaggio di errore: ', message);
            if (message == 'The token has been blacklisted') {
              errorMessage = 'Sessione scaduta. Effettua nuovamente l\'accesso.';
              popupDialogService.popUpDialog(error, errorMessage);
            }else{
              errorMessage = message;
              const obj_request: ApiResponse<any> = {
                code: error.status,
                data: {},
                title: 'Error',
                message: errorMessage,
              }
              // console.log('dentro error status new');
              popupService.alertElement(obj_request);
            }


          }
        );
      } else if (error.status == 500 || error.status == 429) {
        // console.log('dentro error status 3');
        popupDialogService.popUpDialog(error, errorMessage);
        // parte nuova
      } else if (error.status == 518 || error.status == 522 || error.status == 523) {
        const code = error.status;
        let alert_type = null;
        let obj_dialog = null;
        let obj_toast = null;
        if ((error.error && error.error.type_alert) || error.type_alert) {
          alert_type = error.error.type_alert || error.type_alert;
        }
        if ((error.error && error.error.message) || error.message) {
          errorMessage = `${error.error.message || error.message}`;
          // console.log('error info: ',error)
        }
        if ((error.error && error.error.obj_dialog) || error.obj_dialog) {
          obj_dialog = error.error.obj_dialog || error.obj_dialog;
        }
        if ((error.error && error.error.obj_toast) || error.obj_toast) {
          obj_toast = error.error.obj_toast || error.obj_toast;
        }
        const obj_request: ApiResponse<any> = {
          code: code,
          data: {},
          title: 'Error',
          message: errorMessage,
          type_alert: alert_type,
          obj_dialog: obj_dialog,
          obj_toast: obj_toast
        }
        // console.log('dentro error status new');
        popupService.alertElement(obj_request);
      } else {
        // console.log('dentro error status 4');
        popupDialogService.popUpDialog(error, errorMessage);
      }
      return throwError(() => error);
    })
  );
};

