<p class="form-text text-muted">Accedi</p>
<form name="loginForm" #f="ngForm" (keydown.enter)="onEnter($event)">
  <div class="form-group-lg">

    <div class="input-group input-group-lg">
      <!-- email -->
      <input type="email" required [(ngModel)]="email_input" #email="ngModel" class="form-control font_size1"
        name="email" id="email" placeholder="Inserisci email">
    </div>
    <br>
    <!-- password -->
    <div class="input-group input-group-lg">
      <!-- aria-describedby="emailHelp" placeholder="Inserisci email" -->
      <input type="password" class="form-control font_size1" [(ngModel)]="pwd_input" required minlength="6" ngModel
        #password="ngModel" name="password" id="password" placeholder="Inserisci password">
    </div>
    <div *ngIf="email.valid" class="text-end ">
        <button type="button" class="btn btn-link" (click)="forgotPassword(email.value)"><small>Non ricordi la
          password?</small></button>
    </div>
    <!-- <div class="text-start">
      <section class="example-section">
        <mat-checkbox class="example-margin" name="remember" [(ngModel)]="rememberme">Ricordami</mat-checkbox>
      </section>
    </div> -->
    <div><br></div>
    <div class="d-none d-md-block"><br></div>
    <small id="emailHelp" class="form-text text-muted">Software di gestione aziendale per
      piccole e medie imprese. Contattaci se vuoi saperne di più.
    </small>
  </div>
  <div><br></div>
  <div class="d-none d-sm-block"><br></div>
  <div class="d-flex justify-content-between align-items-center">
    <small><a href="https://wallnet.it/#contatti" target="_blank" class="card-link">Contattaci</a></small>
    <button (click)="login(f)" [disabled]="!f.valid" class="btn btn-primary">Avanti</button>
  </div>
  <div class="d-none d-sm-block"><br></div>
</form>
